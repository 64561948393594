import React, { useEffect, useState } from 'react'
import JitsiMeet from '../component/JitsiMeet';
import RealTimeTranscription from '../component/RealTimeTranscription';

const Jitsiapi = () => {
    const [jitsiApi, setJitsiApi] = useState(null);
    const [userName, setUserName] = useState("Bot");
    const [callId, setCallId] = useState("");

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('callId');
      if (id) {
        setCallId(id);
      }
    }, []);
  
    // useEffect(() => {
    //   // Prompt for a display name when joining
    //   const name = prompt("Enter your display name", "Bot");
    //   if (name) setUserName(name);
    // }, []);
  

    
  return (
    <div>
         <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>


          <JitsiMeet
            onApiReady={(api) => {
              setJitsiApi(api);
              api.executeCommand("displayName", userName); // Set the user's display name
            }}
          /> 
         </div> 
        <div style={{ flex: 1}}>
          <RealTimeTranscription callId={callId} api={jitsiApi} userName={userName} />
        </div>
      </div>
      
    </div>
  )
}

export default Jitsiapi
