// Dashboard.js

import React, { useState } from "react"
import Sidebar from "./Sidebar";
import Header from "./Header";
import CRM from "./CRM";
// import Analyze from "./Analyze"
import Callhistory from "./Callhistory";


const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [view, setView] = useState("CRM");
  const [username, setUsername] = useState(localStorage.getItem("username") || "");

  const handleLogout = () => {
    localStorage.removeItem("username");
    window.location.href = "/";
  };

  return (
    <div className="flex h-screen">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setView={setView}
        username={username}
        handleLogout={handleLogout}
      />
      <div className="flex-1 flex flex-col">
        <Header />
        {view === "CRM" && <CRM />}
        {/* {view === "Analyze" && <Analyze/>} */}
        {view === "CallHistory" && <Callhistory />}
      </div>
    </div>
  );
};

export default Dashboard;
