import React, { useState, useCallback } from "react";
import { AlertCircle, Eye, EyeOff, Check, X } from "lucide-react";
import { motion } from 'framer-motion';
import AiImg from "../../assets/LoginImg.webp";
import "./login.css";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    userType: "User",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [shake, setShake] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));

    setErrors(prev => ({
      ...prev,
      [name]: "",
      general: "",
    }));
  };

  const togglePassword = () => {
    setShowPassword(prev => !prev);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.username.trim()) {
      newErrors.username = "Username is required";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/users/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: formData.username,
            password: formData.password,
            userType: formData.userType,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("username", formData.username);
          localStorage.setItem("userType", formData.userType);
          window.location.href = "/dashboard";
        } else {
          setShake(true);
          setErrors({ general: data.message });
          setTimeout(() => setShake(false), 500);
        }
      } catch (error) {
        setErrors({ general: "Server is down, please try again later." });
      } finally {
        setIsLoading(false);
        setLoginAttempts((prev) => prev + 1);
      }
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Side - Login Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8 bg-gradient-to-br from-white to-purple-50">
        <div className="gradient-bg absolute inset-0 blur-md"></div>
        <div className="w-full max-w-md relative bg-white shadow-2xl rounded-xl z-10 p-6">
          <div className="text-left mb-8">
            <h2 className="text-3xl font-bold text-gray-900 text-center">Welcome</h2>
            <p className="mt-2 text-gray-600 text-center">Please enter your details to sign in</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Username Field */}
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                className={`w-full px-4 py-3 rounded-lg border transition-all duration-300 
                           focus:ring-2 ${errors.username ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your username"
                value={formData.username}
                onChange={handleChange}
              />
              {errors.username && (
                <p className="mt-1 text-sm text-red-500 flex items-center">
                  <X className="w-4 h-4 mr-1" />
                  {errors.username}
                </p>
              )}
            </div>

            {/* Password Field */}
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className={`w-full px-4 py-3 rounded-lg border pr-12 transition-all duration-300 
                             focus:ring-2 ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  onClick={togglePassword}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>

            {/* User Type Selection */}
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                User Type
              </label>
              <select
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 transition-all duration-300
                         focus:ring-2 focus:ring-purple-200 focus:border-purple-400"
              >
                <option value="User">User</option>
                <option value="Executive">Executive</option>
              </select>
            </div>

            {/* Error Message */}
            {errors.general && (
              <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50">
                <AlertCircle className="w-5 h-5 mr-1" />
                {errors.general}
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 px-4 text-white bg-purple-600 rounded-lg transition-all duration-300
                       hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
                       disabled:opacity-50 disabled:cursor-not-allowed transform hover:-translate-y-0.5"
            >
              {isLoading ? (
                <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto" />
              ) : (
                "Sign in"
              )}
            </button>

            {/* Forgot Password Link */}
            {loginAttempts > 5 && (
              <div className="text-center text-sm text-gray-600">
                <a href="/reset-password" className="text-purple-600 hover:text-purple-700">
                  Forgot your password?
                </a>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Right Side - Hero Image with Motion */}
      <div className="hidden lg:block w-full lg:w-1/2 relative overflow-hidden">
        {/* Background Overlay */}
        <div className="absolute inset-0 bg-purple-700 mix-blend-multiply"></div>

        {/* Hero Image with Motion */}
        <motion.img
          src={AiImg}
          alt="Login hero"
          className="absolute inset-0 w-full h-full object-cover"
          initial={{ scale: 1.05 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
        />

        {/* Gradient Overlay with Motion */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-t from-purple-900/50 to-purple-900/10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1.5, ease: 'easeInOut' }}
        ></motion.div>

        {/* Content Area with Motion */}
        <div className="absolute bottom-0 left-0 right-0 p-4 lg:p-10 text-white space-y-4 lg:space-y-4">
          <motion.h3
            className="text-3xl lg:text-5xl font-bold mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
          >
            Welcome to CCAI
          </motion.h3>

          <motion.p
            className="text-lg lg:text-xl text-gray-200 mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.5, duration: 1, ease: 'easeInOut' }}
          >
            Manage your customer relationships and monitor real-time data securely with ease.
          </motion.p>

          <motion.p
            className="text-sm lg:text-base text-gray-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2, duration: 1, ease: 'easeInOut' }}
          >
            Log in to access your personalized CRM dashboard.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;