import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CallHistory = () => {
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [selectedCall, setSelectedCall] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSentiment, setSelectedSentiment] = useState("");
  const [updating, setUpdating] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_API}/api/call-history`)
      .then((response) => response.json())
      .then((data) => {
        setCallData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching call history:", error);
        setError("Failed to load call history. Please try again.");
        setLoading(false);
      });
  }, []);

  const filteredData = callData.filter(
    (call) =>
      call.from.toLowerCase().includes(search.toLowerCase()) ||
      call.to.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openSummaryPopup = (call) => {
    setSelectedCall(call);
    setSelectedSentiment(call.qaStatus);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedCall(null);
    setSelectedSentiment("");
  };

  const handleUpdate = () => {
    if (!selectedCall) return;

    if (!selectedSentiment) {
      toast.warn("Please select a sentiment.", { autoClose: 2000 });
      return;
    }

    if (!comment.trim()) {
      toast.warn("Please leave a comment.", { autoClose: 2000 });
      return;
    }

    setUpdating(true);
    fetch(
      `${process.env.REACT_APP_SERVER_API}/api/call-history/${selectedCall._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          qaStatus: selectedSentiment,
          comments: comment,
        }),
      }
    )
      .then((response) => {
        if (!response.ok)
          throw new Error("Failed to update sentiment and comment.");
        return response.json();
      })
      .then((updatedCall) => {
        setCallData((prevData) =>
          prevData.map((call) =>
            call._id === updatedCall._id ? updatedCall : call
          )
        );
        toast.success("Sentiment and comment updated successfully!", {
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error("Error updating sentiment and comment:", error);
        toast.error("Failed to update sentiment and comment. Please try again.", {
          autoClose: 2000,
        });
      })
      .finally(() => setUpdating(false));
  };
  const exportToCSV = () => {
    const headers = [
      "ID",
      "From",
      "To",
      "Start Time",
      "End Time",
      "Agent Status",
      "QA Status",
      "QA Comments",
      "Recording URL",
      "Transcription",
    ];

    const data = callData.map((call, index) => [
      index + 1,
      call.from,
      call.to,
      new Date(call.startTime).toLocaleString(),
      new Date(call.endTime).toLocaleString(),
      call.agentSentiment,
      call.qaStatus,
      call.comments || "N/A",
      call.recordingUrl || "N/A",
      call.diarization || "N/A",
    ]);

    const csvContent = [headers, ...data]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "call_history.csv";
    link.click();
  };

  const exportToXLSX = () => {
    const sheetData = callData.map((call, index) => ({
      ID: index + 1,
      From: call.from,
      To: call.to,
      "Start Time": new Date(call.startTime).toLocaleString(),
      "End Time": new Date(call.endTime).toLocaleString(),
      "Agent Status": call.agentSentiment,
      "QA Status": call.qaStatus,
      "QA Comments": call.comments || "N/A",
      "Recording URL": call.recordingUrl || "N/A",
      Transcription: call.diarization.map(d => `${d.speaker}: ${d.text}`).join('   |   ') || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Call History");

    XLSX.writeFile(workbook, "call_history.xlsx");
  };

  return (
    <div className="p-6 bg-gray-50">
      <ToastContainer />
      {/* Header */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex justify-between items-center mb-6"
      >
        <h1 className="text-3xl font-bold text-gray-900">Call History</h1>
        <div className="space-x-4">
          <button
            className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none"
            onClick={exportToCSV}
          >
            Export to CSV
          </button>
          <button
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
            onClick={exportToXLSX}
          >
            Export to XLSX
          </button>
        </div>
      </motion.div>

      {/* Search Bar */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="mb-6"
      >
        <input
          type="text"
          placeholder="Search by From or To"
          className="w-full md:w-1/4 px-4 py-3 border border-gray-300 rounded-xl shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out transform focus:scale-105"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </motion.div>

      {/* Loading and Error States */}
      {loading && (
        <motion.div
          className="flex justify-center items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="animate-spin w-8 h-8 border-4 border-t-4 border-blue-500 rounded-full"></div>
        </motion.div>
      )}
      {error && (
        <motion.p
          className="text-red-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {error}
        </motion.p>
      )}

      {/* Call History Table */}
      {!loading && !error && (
        <motion.div
          className="overflow-x-auto bg-white shadow-lg rounded-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="sticky top-0 bg-gradient-to-r from-blue-500 to-blue-700 text-white">
              <tr>
                <th className="py-3 px-3 text-center border-b">ID</th>
                <th className="py-3 px-3 text-center border-b">From</th>
                <th className="py-3 px-3 text-center border-b">To</th>
                <th className="py-3 px-3 text-center border-b">Start Time</th>
                <th className="py-3 px-3 text-center border-b">End Time</th>
                <th className="py-3 px-3 text-center border-b">Agent Status</th>
                <th className="py-3 px-3 text-center border-b">QA Status</th>
                <th className="py-3 px-3 text-center border-b">QA Comments</th>
                <th className="py-3 px-3 text-center border-b">Summary</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((call, index) => (
                <motion.tr
                  key={call._id}
                  className="hover:bg-gray-100 even:bg-gray-50 transition-all duration-200 ease-in-out transform"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <td className="py-3 px-3 border-b text-center">
                    {indexOfFirstRecord + index + 1}
                  </td>
                  <td className="py-3 px-3 border-b text-center">
                    {call.from}
                  </td>
                  <td className="py-3 px-3 border-b text-center">{call.to}</td>
                  <td className="py-3 px-3 border-b text-center">
                    {call.startTime}
                  </td>
                  <td className="py-3 px-3 border-b text-center">
                    {call.endTime}
                  </td>
                  <td className="py-3 px-4 border-b text-center">
                    <span
                      className={`px-3 py-1 rounded-lg text-sm ${call.agentSentiment === "Positive"
                        ? "bg-green-100 text-green-700"
                        : call.agentSentiment === "Negative"
                          ? "bg-red-100 text-red-700"
                          : call.agentSentiment === "Neutral"
                            ? "bg-yellow-100 text-yellow-700"
                            : ""
                        }`}
                    >
                      {call.agentSentiment}
                    </span>
                  </td>
                  <td className="py-3 px-4 border-b text-center">
                    <span
                      className={`px-3 py-1 rounded-lg text-sm ${call.qaStatus === "Positive"
                        ? "bg-green-100 text-green-700"
                        : call.qaStatus === "Negative"
                          ? "bg-red-100 text-red-700"
                          : call.qaStatus === "Neutral"
                            ? "bg-yellow-100 text-yellow-700"
                            : ""
                        }`}
                    >
                      {call.qaStatus}
                    </span>
                  </td>
                  <td className="py-3 px-3 border-b text-center">
                    <div className="max-h-20 overflow-y-auto">
                      {call.comments}
                    </div>
                  </td>
                  <td className="px-3 py-2 border-b text-center">
                    <button
                      className="px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
                      onClick={() => openSummaryPopup(call)}
                    >
                      View Summary
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      )}

      {/* Pagination */}
      {!loading && !error && filteredData.length > recordsPerPage && (
        <motion.div
          className="mt-6 flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex space-x-3">
            {Array.from(
              { length: Math.ceil(filteredData.length / recordsPerPage) },
              (_, index) => (
                <motion.li key={index}>
                  <button
                    className={`px-6 py-2 rounded-xl text-white ${currentPage === index + 1
                      ? "bg-blue-500 shadow-lg"
                      : "bg-gray-300"
                      }`}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </motion.li>
              )
            )}
          </ul>
        </motion.div>
      )}

      {/* Popup */}
      {isPopupOpen && selectedCall && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex justify-center items-center z-50">
          <motion.div
            className="bg-white/80 backdrop-blur-lg p-10 rounded-3xl w-11/12 max-w-4xl shadow-3xl relative flex flex-col md:grid md:grid-cols-2 gap-10 border border-gray-200"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {/* Close Icon */}
            <button
              className="absolute top-6 right-6 text-gray-500 hover:text-red-600 transition-all duration-300"
              onClick={closePopup}
              aria-label="Close popup"
            >
              <MdClose className="text-3xl" />
            </button>

            {/* Left Panel */}
            <div className="flex flex-col gap-8">
              <h2 className="text-4xl font-extrabold text-gray-800 tracking-wide">
                Sentiment Recommendation
              </h2>
              <div className="relative">
                <div
                  className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-300 rounded-2xl p-4 text-lg text-gray-700 cursor-pointer flex justify-between items-center shadow-lg hover:shadow-xl transition-all"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedSentiment || "Choose sentiment"}
                  <svg
                    className={`w-6 h-6 transform transition-transform ${dropdownOpen ? "rotate-180" : ""
                      }`}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {dropdownOpen && (
                  <div className="absolute mt-2 bg-white/90 border border-gray-300 rounded-xl shadow-lg w-full z-10 overflow-hidden backdrop-blur-md">
                    {["Positive", "Neutral", "Negative"].map((option) => (
                      <div
                        key={option}
                        className="p-4 hover:bg-gradient-to-r from-blue-50 to-blue-100 text-gray-800 cursor-pointer transition-all duration-300"
                        onClick={() => {
                          setSelectedSentiment(option);
                          setDropdownOpen(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Comment Input */}
              <div>
                <h2 className="text-lg font-semibold text-gray-700">
                  Leave a Comment
                </h2>
                <textarea
                  className="mt-2 p-4 w-full h-24 border border-gray-300 rounded-2xl bg-gray-50 text-gray-700 text-lg leading-relaxed shadow-inner focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none"
                  placeholder="Write your comment here..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <button
                className={`px-6 py-2 w-40 text-lg font-semibold rounded-full transition-all duration-300 ${updating
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-500 text-white hover:bg-blue-600"
                  }`}
                onClick={handleUpdate}
                disabled={updating}
              >
                {updating ? "Updating..." : "Update"}
              </button>

              {/* Audio Player */}
              <div>
                <h2 className="text-4xl font-extrabold text-gray-800 tracking-wide">
                  Call Recording
                </h2>
                {selectedCall.recordingUrl ? (
                  <audio
                    className="w-full mt-4 rounded-full border border-gray-300 shadow-md"
                    controls
                    src={selectedCall.recordingUrl}
                  >
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <p className="text-gray-600 mt-2">No recording available.</p>
                )}
              </div>
            </div>

            {/* Right Panel */}
            <div className="flex flex-col gap-6">
              <h2 className="text-4xl font-extrabold text-gray-800 tracking-wide">
                Transcription
              </h2>
              <div className="flex-grow p-6 border border-gray-300 rounded-2xl bg-gray-50/80 text-gray-700 text-lg leading-relaxed overflow-auto max-h-96 shadow-inner">
                {/* {selectedCall.summary || "No summary available."} */}
                {selectedCall.diarization && selectedCall.diarization.length > 0 ? (
                  <ul className="mt-2">
                    {selectedCall.diarization.map((entry, index) => (
                      <li key={index} className="text-gray-600">
                        <strong>{entry.speaker}:</strong> {entry.text || "No speech"}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 mt-2">No diarization details available.</p>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default CallHistory;
