import React, { useState } from "react";
import * as XLSX from "xlsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { BiPhone } from "react-icons/bi";
import { motion } from "framer-motion";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { createCall } from '../../api/CallApi';
import "react-toastify/dist/ReactToastify.css";

const CRM = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCRMType, setSelectedCRMType] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);


  const handleCreateCallWithEndpoint = async (name, jitsiEndpoint) => {
    try {
      // Original handleCreateCall invocation
      const response = await createCall(name); // Create call and get response

      if (response && response.callId) {
        const url = `/Jitsiapi?callId=${encodeURIComponent(
          response.callId
        )}&jitsiEndpoint=${encodeURIComponent(
          jitsiEndpoint || "SupportRoom"
        )}`;
        window.open(url, "_blank");
      } else {
        console.error("Error: No callId returned from createCall");
      }
    } catch (error) {
      console.error("Error in handleCreateCallWithEndpoint:", error);
    }
  };

  // const handleExcelImport = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //     setIsLoading(true);
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const binaryStr = event.target.result;
  //       const workbook = XLSX.read(binaryStr, { type: "binary" });
  //       const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet);

  //       // Format dates in the data while skipping specific fields
  //       const formattedData = jsonData.map((row) => {
  //         const formattedRow = { ...row };
  //         for (const key in formattedRow) {
  //           if (
  //             key.toLowerCase() !== "id" && // Skip the 'id' field
  //             isValidExcelDate(formattedRow[key])
  //           ) {
  //             formattedRow[key] = formatExcelDate(formattedRow[key]);
  //           }
  //         }
  //         return formattedRow;
  //       });

  //       setData((prevData) => [...prevData, ...formattedData]);
  //       setIsLoading(false);
  //     };
  //     reader.readAsBinaryString(file);
  //   }
  // };

  // Helper function to check if a value is an Excel date

  const handleExcelImport = (e) => {
    const file = e.target.files[0];

    // Clear the file input to allow re-upload of the same file
    e.target.value = null;

    if (file) {
      // Validate file type
      const validExtensions = [".xlsx", ".xls", ".csv"];
      const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
      if (!validExtensions.includes(fileExtension)) {
        toast.error("Invalid file type. Please upload an Excel file.");
        return;
      }

      setFileName(file.name);
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Format dates in the data while skipping specific fields
        const formattedData = jsonData.map((row) => {
          const formattedRow = { ...row };
          for (const key in formattedRow) {
            if (
              key.toLowerCase() !== "id" && // Skip the 'id' field
              isValidExcelDate(formattedRow[key])
            ) {
              formattedRow[key] = formatExcelDate(formattedRow[key]);
            }
          }
          return formattedRow;
        });

        setData((prevData) => [...prevData, ...formattedData]);
        setIsLoading(false);
      };
      reader.readAsBinaryString(file);
    }
  };

  const isValidExcelDate = (value) => {
    return !isNaN(value) && value > 0 && value < 2958465; // Excel serial date range
  };

  // Helper function to format Excel serial date to 'YYYY-MM-DD HH:MM:SS'
  const formatExcelDate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert Excel date to JS date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
    const hours = String(date.getHours()).padStart(2, "0"); // Add leading zero to hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Add leading zero to minutes
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Add leading zero to seconds
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as 'YYYY-MM-DD HH:MM:SS'
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = data.filter((row) => {
    const matchesType =
      selectedCRMType === "All" ||
      (row.crmType && row.crmType.toLowerCase() === selectedCRMType.toLowerCase());
    const matchesSearch =
      row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email?.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesType && matchesSearch;
  });

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <motion.div
      className="overflow-y-hidden bg-gray-50 flex flex-col items-center p-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Header Section */}
      <div className="w-full max-w-7xl space-y-6">
        <div className="flex justify-between items-center bg-white shadow-lg p-6 rounded-xl">
          <h1 className="text-2xl font-semibold text-gray-800">CRM Dashboard</h1>
          <div className="flex items-center gap-6">
            <select
              value={selectedCRMType}
              onChange={(e) => setSelectedCRMType(e.target.value)}
              className="bg-gray-200 text-gray-800 border border-gray-300 rounded-lg py-3 px-5 shadow-md focus:ring-2 focus:ring-blue-600"
            >
              <option value="All">All CRM Types</option>
              <option value="Sales CRM">Sales CRM</option>
              <option value="Support CRM">Support CRM</option>
              <option value="Marketing CRM">Marketing CRM</option>
            </select>
            <label
              htmlFor="file-upload"
              className="bg-blue-600 text-white py-3 px-5 rounded-lg cursor-pointer hover:bg-blue-700 transition-all"
            >
              Import Excel
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx, .xls, .csv"
              onChange={handleExcelImport}
              className="hidden"
            />
          </div>
        </div>

        {/* Search Bar */}
        <div className="w-full max-w-7xl relative">
          <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-white border border-gray-300 rounded-lg pl-12 pr-8 py-4 shadow-md focus:ring-2 focus:ring-blue-600"
          />
          {searchTerm && (
            <AiOutlineClose
              onClick={() => setSearchTerm("")}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              size={20}
            />
          )}
        </div>

        {/* Loading Spinner */}
        {isLoading && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 animate-pulse">
            <AiOutlineLoading3Quarters className="animate-spin text-white" size={48} />
          </div>
        )}

        {/* Data Table */}
        <div className="w-full max-w-7xl bg-white rounded-lg shadow-lg overflow-hidden mt-8">
          {data.length ? (
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead className="bg-blue-600 text-white">
                  <tr>
                    {["ID", "Name", "Call Started", "Call Ended", "Status", "Comments", "Jitsi Endpoint", "Email", "Call"].map(
                      (header, index) => (
                        <th
                          key={index}
                          className="px-4 py-3 text-md font-semibold text-center"
                        >
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentData.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-100 transition-all">
                      <td className="px-4 py-3 text-md text-center truncate">{row.id || "-"}</td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.name || "-"}</td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.callStarted || "-"}</td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.callEnded || "-"}</td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.status === "Completed" && (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {row.status}
                        </span>
                      )}
                        {row.status === "In-progress" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            {row.status}
                          </span>
                        )}
                        {row.status === "Open" && (
                          <span className="px-6 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                            {row.status}
                          </span>
                        )}</td>
                      <td className="px-4 py-3 text-md text-center truncate">
                        <div className="relative group">
                          <div className="truncate max-w-xs">{row.comments || "-"}</div>
                          {row.comments && (
                            <div className="absolute top-0 left-0 w-full h-full bg-gray-700 text-white opacity-0 group-hover:opacity-100 transition-all p-2 text-xs">
                              {row.comments}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.jitsiEndpoint || "-"}</td>
                      <td className="px-4 py-3 text-md text-center truncate">{row.email || "-"}</td>
                      <td className="px-4 py-3 text-md text-center">
                        <button
                          onClick={() => handleCreateCallWithEndpoint(row.name, row.jitsiEndpoint)}
                          className="text-blue-600 hover:text-blue-800 focus:outline-none"
                          aria-label={`Call ${row.email}`}
                        >
                          <BiPhone size={22} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No data available. Import an Excel file to get started.
            </div>
          )}
        </div>

        {/* Pagination */}
        <div className="flex justify-center space-x-4 mt-6">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50 hover:bg-blue-200 transition-all"
          >
            Prev
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-4 py-2 rounded-md ${currentPage === index + 1 ? "bg-blue-600 text-white font-semibold" : "bg-gray-200 hover:bg-gray-300"
                } transition-all`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50 hover:bg-blue-200 transition-all"
          >
            Next
          </button>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </motion.div>
  );
};

export default CRM;
