// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-bg {
  background: linear-gradient(45deg, #a855f7, #d8b4fe, #f5d0fe, #a78bfa, #c4b5fd);
  background-size: cover; /* Ensures the gradient covers the entire element */
}
`, "",{"version":3,"sources":["webpack://./src/component/login/login.css"],"names":[],"mappings":"AAAA;EACE,+EAA+E;EAC/E,sBAAsB,EAAE,mDAAmD;AAC7E","sourcesContent":[".gradient-bg {\r\n  background: linear-gradient(45deg, #a855f7, #d8b4fe, #f5d0fe, #a78bfa, #c4b5fd);\r\n  background-size: cover; /* Ensures the gradient covers the entire element */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
