import React, { useState, useEffect, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Recorder from "recorder-js";
import { FaSpinner } from "react-icons/fa"; // Optional, if you want a spinner icon
import AWS from "aws-sdk";  // Import AWS SDK
import { updateCall } from '../api/CallApi';

const { GoogleGenerativeAI } = require("@google/generative-ai");

const RealTimeTranscription = ({ callId, api, userName }) => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const [messages, setMessages] = useState([]);
  const [analysis, setAnalysis] = useState(null);
  const [debouncedTranscript, setDebouncedTranscript] = useState("");
  const [popupMessage, setPopupMessage] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(null);

  const [startTime, setStartTime] = useState(null);
  const [stopTime, setStopTime] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // Loading state

  const debounceDelay = 3000;

  const genAi = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API);
  const model = genAi.getGenerativeModel({
    model: "gemini-1.5-pro",
  });

  const recorder = useRef(null);
  const audioContext = useRef(null);


  // AWS S3 Setup
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  useEffect(() => {
    if (api) {
      api.addListener("incomingMessage", (msg) => {
        const senderName = api.getParticipantById(msg.id)?.getDisplayName() || "Unknown";
        setMessages((prev) => [...prev, { sender: senderName, text: msg.message }]);
      });
    }
  }, [api]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTranscript(transcript);
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [transcript]);

  useEffect(() => {
    if (debouncedTranscript) {
      analyzeConversation(debouncedTranscript);
    }
  }, [debouncedTranscript]);

  const handleStartStopListening = () => {
    if (listening) {
      // Stop listening if already listening
      SpeechRecognition.stopListening();
    } else {
      // Start listening if not already listening
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  const analyzeConversation = async (text) => {
    if (!text) return;

    setIsLoading(true); // Start loading when analyzing conversation

    try {
      // Prompt template to analyze sentiment and diarize the conversation
      const prompt = `
        Analyze the sentiment and diarization of the following conversation.
        Return only a valid JSON object without any additional formatting.
        Example format:
        {
          "sentiment": "Positive", 
          "diarization": [
            { "speaker": "Consumer", "text": "I would like to know more about the product." },
            { "speaker": "Customer", "text": "Sure, let me explain." }
          ]
        }
  
        Text: "${text}"
      `;

      // Generate content from the model based on the prompt
      const response = await model.generateContent(prompt);
      const resultText = await response.response.text();

      // Sanitize the result to remove unnecessary formatting
      const sanitizedText = resultText.replace(/```json|```/g, "").trim();
      const result = JSON.parse(sanitizedText);

      // Clean up diarization data to ensure valid format
      const cleanedDiarization = result.diarization.map((entry) => ({
        speaker: entry.speaker,
        text: entry.text || "No speech"
      }));

      // Update the state with sentiment and diarization data
      setAnalysis({
        sentiment: result.sentiment,
        diarization: cleanedDiarization
      });

      // If the sentiment indicates a likely purchase, include that analysis
      const wouldBuy = result.sentiment === "Positive" ? "Yes" : "No";
      setPopupMessage(`Customer will likely buy: ${wouldBuy}`);
      // console.log("Analysis Result:", result);
    } catch (error) {
      console.error("Error during analysis:", error.message);
      setAnalysis({ sentiment: "Error analyzing sentiment.", diarization: [] });
    } finally {
      setIsLoading(false); // Stop loading after the API call
    }
  };

  const handlePopup = (result) => {
    if (result.includes("Yes")) {
      setPopupMessage("Response is Positive, The call is transferring to the executive.");
    } else if (result.includes("No")) {
      setPopupMessage("Response is Negative, The call is adding to the next queue.");
    } else {
      setPopupMessage(null);
    }
  };

  const handleSendTranscript = () => {
    if (api && transcript) {
      api.executeCommand("sendEndpointTextMessage", "", transcript);
      setMessages((prev) => [...prev, { sender: userName, text: transcript }]);
      resetTranscript();
    }
  };

  const startRecording = async () => {
    try {
      setIsLoading(true); // Start loading when recording starts
      // Initialize audio context and recorder
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorder.current = new Recorder(audioContext.current);
      recorder.current.init(stream);
      await recorder.current.start();
      setIsRecording(true);

      // Record start time
      const recordingStartTime = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Singapore', // GMT +8 timezone
      });

      // console.log("Local time : ",new Date());
      // console.log("ISOString time : ",new Date().toISOString());
      // console.log("Recording started at:", recordingStartTime);
      setStartTime(recordingStartTime);

    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    try {
      // Stop recording and retrieve the audio blob
      const { blob } = await recorder.current.stop();
      setIsRecording(false);

      // Record stop time
      const recordingStopTime = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Singapore', // GMT +8 timezone
      });
      setStopTime(recordingStopTime);

      // Upload the audio to S3
      const recordingUrl = await uploadToS3(blob);

      // Prepare sentiment and diarization data
      const agentSentiment = analysis?.sentiment || "Unknown";
      const diarizationObject = analysis?.diarization || [];

      // Update the call with recording details
      if (callId) {
        await updateCall(callId, {
          startTime,
          endTime: recordingStopTime,
          recordingUrl,
          agentSentiment,
          diarizationObject,
        });
        // console.log("Recording stopped and call updated successfully.");
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    } finally {
      setIsLoading(false); // Stop loading after recording
    }
  };

  const uploadToS3 = async (audioBlob) => {
    const fileName = `recording-${Date()}.mp3`; // Use current timestamp as filename
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `audio/${fileName}`,
      Body: audioBlob,
      ContentType: "audio/mp3",
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading to S3:", err);
          reject(err);
        } else {
          // console.log("Successfully uploaded to S3:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  if (!browserSupportsSpeechRecognition) {
    return <p className="text-center text-red-500">Your browser doesn't support speech recognition.</p>;
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gray-100 text-gray-800">
      {/* Sidebar */}
      <aside className="w-full md:w-1/4 bg-white shadow-lg rounded-lg p-6 space-y-8">
        <h2 className="text-lg font-semibold text-gray-700">Quick Actions</h2>

        {/* Listening Button */}
        <button
          onClick={handleStartStopListening}
          className={`w-full py-3 rounded-lg text-lg font-medium transition ${listening
            ? "bg-green-500 text-white hover:bg-green-600"
            : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
        >
          {listening ? "Stop Listening" : "Start Listening"}
        </button>

        {/* Recording Button */}
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`w-full py-3 rounded-lg text-lg font-medium transition ${isRecording
            ? "bg-red-500 text-white hover:bg-red-600"
            : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
          disabled={isLoading} // Disable when loading
        >
          {isLoading ? (
            <div className="flex items-center justify-center space-x-2">
              <FaSpinner className="animate-spin text-white" />
            </div>
          ) : isRecording ? "Stop Recording" : "Start Recording"}
        </button>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-6 space-y-8">
        {/* Header */}
        <header className="bg-blue-700 text-white py-4 px-6 rounded-lg shadow">
          <h1 className="text-2xl font-semibold text-center">Meeting Transcription & Analysis</h1>
        </header>

        {/* Status Section */}
        <section className="bg-white p-6 rounded-lg shadow flex flex-col md:flex-row items-center justify-between">
          <p className="text-lg">
            Listening Status:{" "}
            <span className={`font-bold ${listening ? "text-green-500" : "text-red-500"}`}>
              {listening ? "Active" : "Inactive"}
            </span>
          </p>
          <p className="text-lg">
            Recording Status:{" "}
            <span className={`font-bold ${isRecording ? "text-yellow-500" : "text-gray-500"}`}>
              {isRecording ? "In Progress" : "Idle"}
            </span>
          </p>
        </section>

        {/* Transcript Section */}
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Transcript</h2>
          <div className="bg-gray-100 p-4 rounded-lg min-h-[100px]">
            {transcript ? (
              <p className="text-gray-600">{transcript}</p>
            ) : (
              <p className="text-gray-500 italic">No transcript available yet.</p>
            )}
          </div>
        </section>

        {/* Analysis Section */}
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Analysis</h2>
          <div className="bg-gray-100 p-4 rounded-lg">
            {analysis ? (
              <>
                {/* Display Sentiment */}
                <p className="text-gray-600">
                  <strong>Sentiment:</strong> {analysis.sentiment || "Not available"}
                </p>

                {/* Display Diarization */}
                {analysis.diarization && analysis.diarization.length > 0 ? (
                  <ul className="mt-2">
                    {analysis.diarization.map((entry, index) => (
                      <li key={index} className="text-gray-600">
                        <strong>{entry.speaker}:</strong> {entry.text || "No speech"}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 mt-2">No diarization details available.</p>
                )}
              </>
            ) : (
              <p className="text-gray-500 italic">No analysis yet.</p>
            )}
          </div>
        </section>
      </main>

      {/* Popup Message
    {popupMessage && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <p className="text-lg font-semibold mb-4">{popupMessage}</p>
          <button
            onClick={() => setPopupMessage(null)}
            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition"
          >
            Close
          </button>
        </div>
      </div>
    )} */}
    </div>
  );

};

export default RealTimeTranscription;
