import React, { useState } from "react";
import { BellIcon } from "@heroicons/react/24/solid";
import { PiHandWaving } from "react-icons/pi";

const Header = () => {
  const today = new Date();
  const [username, setUsername] = useState(localStorage.getItem("username") || "User");
  const [notifications, setNotifications] = useState(["Test notification!"]);
  const [showDropdown, setShowDropdown] = useState(false);

  const formattedDate = today.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  // Dynamic greeting based on time of day
  const hour = new Date().getHours();
  const getGreeting = () => {
    if (hour >= 21 || hour < 6) return "Good Night"; 
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening"; 
  };

  const handleClearNotifications = () => {
    setNotifications([]);
    setShowDropdown(false);
  };

  return (
    <div className="flex justify-between items-center p-4 border-b shadow-md bg-gradient-to-r from-gray-900 via-gray-450 to-gray-300">
      {/* Left: Greeting and Date */}
      <div className="flex items-center space-x-4">
        <div
          className="flex items-center space-x-2"
          style={{
            animation: "fadeIn 1s ease-in-out"
          }}
        >
          <PiHandWaving className="h-7 w-7 text-yellow-500 wave-animation" />
          <span className="text-lg font-bold text-gray-700">
            <span className="text-blue-600">{getGreeting()}, {username}!</span>
          </span>
        </div>
        <span className="text-white text-sm">{formattedDate}</span>
      </div>

      {/* Right: Notification Bell */}
      <div className="relative text-gray-500">
        <div
          className="relative cursor-pointer hover:text-blue-600 transition duration-300"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <BellIcon className="h-6 w-6" />
          {notifications.length > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full px-1">
              {notifications.length}
            </span>
          )}
        </div>

        {/* Notification Dropdown */}
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg p-4 z-10">
            {notifications.length > 0 ? (
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-bold text-gray-700">Notifications</span>
                  <button
                    className="text-xs text-blue-500 hover:underline"
                    onClick={handleClearNotifications}
                  >
                    Clear All
                  </button>
                </div>
                <ul className="space-y-2">
                  {notifications.map((notification, index) => (
                    <li
                      key={index}
                      className="text-sm text-gray-600 bg-gray-100 p-2 rounded-md hover:bg-gray-200 transition"
                    >
                      {notification}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="text-sm text-gray-500">No new notifications</div>
            )}
          </div>
        )}
      </div>

      {/* CSS Animations */}
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes wave {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(20deg);
          }
          50% {
            transform: rotate(0deg);
          }
          75% {
            transform: rotate(-20deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }

        .wave-animation {
          animation: wave 1.5s infinite;
          transform-origin: 50% 50%; /* Adjusts the pivot point for rotation */
        }
      `}</style>
    </div>
  );
};

export default Header;
