import React from "react";
import { BiSolidDashboard, BiUser, BiLogOut } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, setView, handleLogout, username }) => {
  return (
    <aside
      className={`${
        isSidebarOpen ? "w-60" : "w-16"
      } bg-gray-800 text-white transition-all duration-300 ease-in-out flex flex-col`}
      aria-label="Sidebar"
    >
      <div className="p-4">
        <div className="flex items-center justify-between">
          {isSidebarOpen && <h2 className="text-xl font-bold">Dashboard</h2>}
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="rounded hover:bg-gray-700 transition-colors"
            aria-label="Toggle sidebar"
          >
            <FiMenu size={24} />
          </button>
        </div>
      </div>
      <nav className="mt-4 flex-grow">
        <div className="px-4 py-2 text-gray-400 uppercase text-sm">
          {isSidebarOpen && "Call Room"}
        </div>
        <ul className="space-y-2 px-2">
          <li>
            <button
              onClick={() => setView("CRM")}
              className="flex items-center w-full p-2 rounded hover:bg-gray-700 transition-colors"
              aria-label="CRM"
            >
              <BiSolidDashboard size={24} />
              {isSidebarOpen && <span className="ml-3">CRM</span>}
            </button>
          </li>
          {/* <li>
            <button
              onClick={() => setView("Analyze")}
              className="flex items-center w-full p-2 rounded hover:bg-gray-700 transition-colors"
              aria-label="Analyze"
            >
              <BiSolidDashboard size={24} />
              {isSidebarOpen && <span className="ml-3">Analyze</span>}
            </button>
          </li> */}
          <li>
            <button
              onClick={() => setView("CallHistory")}
              className="flex items-center w-full p-2 rounded hover:bg-gray-700 transition-colors"
              aria-label="CallHistory"
            >
              <BiSolidDashboard size={24} />
              {isSidebarOpen && <span className="ml-3">Call History</span>}
            </button>
          </li>
        </ul>
      </nav>

      <div className="mt-auto px-3">
        <button
          onClick={handleLogout}
          className="flex items-center w-full p-2 rounded hover:bg-gray-700 transition-colors"
          aria-label="Logout"
        >
          <BiLogOut size={24} />
          {isSidebarOpen && <span className="ml-3">Logout</span>}
        </button>

        <div className="flex items-center w-full p-2 rounded transition-colors mt-4">
          <BiUser size={24} />
          {isSidebarOpen && <span className="ml-3">{username}</span>}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
