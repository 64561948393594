export const createCall = async (name) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/call-jitsi/create-call`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ to: name }),
        });

        if (response.ok) {
            const result = await response.json();
            // console.log('Backend response:', result); // Verify the structure
            return result; // Return the parsed response here
        } else {
            console.error('Failed to create call history entry');
            return null;
        }
    } catch (error) {
        console.error('Error during Jitsi call:', error);
        return null;
    }
};

export const updateCall = async (callId, data) => {
   const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/call-jitsi/update-call`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ callId, ...data }),
    });
    return response.json();
};

